import { NewsPostLocaleContent } from 'Shared/types/news';

export const validateAllFieldsHaveValues = (
  allNews: NewsPostLocaleContent
): {
  isValid: boolean;
  emptyFields: Record<string, Record<string, string>[]>;
} => {
  let isValid = true;
  const emptyFields = {};
  Object.keys(allNews).forEach((locale) => {
    emptyFields[locale] = {};
    const localeNewsItem = allNews[locale];
    Object.keys(localeNewsItem).forEach((key) => {
      if (localeNewsItem[key] === '') {
        // as soon as one value is empty, return
        emptyFields[locale][key] = `${key} field is required.`;
        isValid = false;
        return;
      }
      return;
    });
    return;
  });
  return { isValid, emptyFields };
};
