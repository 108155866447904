import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryResult, QueryLazyOptions } from '@apollo/client';
import { NewsPostLocaleItem } from 'Shared/types/news';
import { useProject } from 'Client/utils/hooks';
import { TextInput } from 'Client/components/molecules/TextInput/TextInput';
import { convertToSlug } from 'Client/utils/stringManipulations';
import { ErrorStatusOrHelperText } from 'Atoms';
import { NoSSRFroalaContentEditor } from './FroalaContentEditor/NoSSRFroalaContentEditor';

interface AddNewsFormProps {
  newsPost: NewsPostLocaleItem;
  onChange: (f: string, v: string) => void;
  slug: string;
  type: 'add' | 'edit';
  locale?: string;
  setSlug?: React.Dispatch<React.SetStateAction<string>>;
  getNewsBySlug?: (
    options?: QueryLazyOptions<{ slug: string; projectId: string }>
  ) => Promise<LazyQueryResult<any, { slug: string; projectId: string }>>;
  error?: boolean;
  errorMessage?: string;
  emptyFieldErrors?: Record<string, Record<string, string>>;
}

export const AddNewsForm: React.FC<AddNewsFormProps> = ({
  newsPost,
  onChange,
  slug,
  setSlug,
  getNewsBySlug,
  type,
  locale,
  error,
  errorMessage,
  emptyFieldErrors,
}: AddNewsFormProps) => {
  const project = useProject();
  // every on change will be traced back at the big object with all news
  // because we don't know how many locales we may have on each case
  // and because we want the content in the parent anyway
  const { t } = useTranslation('customer');
  if (!newsPost) return <>no data</>;

  const handleChange =
    (fieldName: string) => async (e: React.ChangeEvent<{ value: string }>) => {
      const val = e.target.value;
      const convertedString = convertToSlug(val);
      if (fieldName === 'headline' && type === 'add' && locale == 'en-GB') {
        setSlug(convertedString);
      }
      onChange(fieldName, val);
      await getNewsBySlug({
        variables: { slug: convertedString, projectId: project?._id },
      });
    };

  const handleContentChange = (fieldName: string) => (newContent: string) => {
    onChange(fieldName, newContent);
  };

  return (
    <>
      <TextInput
        label={t('News headline')}
        placeholder={t('Title of your article')}
        onChange={handleChange('headline')}
        value={newsPost.headline}
        error={!!emptyFieldErrors?.[locale]?.headline}
        errorMessage={emptyFieldErrors?.[locale]?.headline}
      />
      {locale === 'en-GB' && (
        <TextInput
          label={t('URL')}
          placeholder={t('URL')}
          onChange={() => ''}
          value={slug}
          error={error}
          errorMessage={errorMessage}
        />
      )}
      <TextInput
        onChange={handleChange('author')}
        label={t('Author')}
        placeholder={t('Author')}
        value={newsPost.author}
        error={!!emptyFieldErrors?.[locale]?.author}
        errorMessage={emptyFieldErrors?.[locale]?.author}
      />
      <div>
        <NoSSRFroalaContentEditor
          inputId="news-content"
          label={t('News post content')}
          value={newsPost.content}
          handleChange={handleContentChange('content')}
        />
        {emptyFieldErrors?.[locale]?.content && (
          <>
            <ErrorStatusOrHelperText
              status={{
                type: 'error',
                message: emptyFieldErrors[locale].content,
              }}
            />
          </>
        )}
      </div>
    </>
  );
};
